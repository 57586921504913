import {
  ActionButton,
  BetaTag,
  BreakStyle,
  defineStylesWithBreaks,
  galleryHeaderHeight,
  pagePadding,
  themeIsDark,
  useGLTranslation,
  useTheme,
  useThemedStylesWithBreaks
} from 'notability-services-common-frontend'
import {addingBasePath} from '../config'
import {useCustomRouter} from '../util/useCustomRouter'
import {useLogout} from '../util/clientsideUtils'
import {useLoggedIn} from '../util/GalleryContext'

export const galleryHeaderPaddingMobileTop = '27px'
export const galleryHeaderPaddingTop = '24px'

type LoginHeaderProps = {
  useBetaTag?: boolean
  showLogout?: boolean
  solidBacking?: boolean
}

const styles = defineStylesWithBreaks((theme) => {
  const header: BreakStyle = {
    height: galleryHeaderHeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    position: 'relative',
    zIndex: 20,
    padding: `${galleryHeaderPaddingTop} ${pagePadding.paddingRight}`,

    mobile: {
      padding: `${galleryHeaderPaddingMobileTop} 20px`
    }
  }

  return {
    header,

    headerSolid: {
      ...header,
      backgroundColor: theme.core.b1,
      borderBottom: `1px solid ${theme.core.b4}`
    },

    headerImage: {
      height: '35px',
      cursor: 'pointer'
    },

    headerLeftContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexShrink: 0,
      gap: '7px'
    }
  }
})

export function LoginHeader({useBetaTag, showLogout, solidBacking}: LoginHeaderProps) {
  const s = useThemedStylesWithBreaks(styles)
  const router = useCustomRouter()
  const {t} = useGLTranslation()

  const {theme} = useTheme()
  const logoPath = themeIsDark(theme.name) ? '/notability-logo-dark.svg' : '/notability-logo-light.svg'

  const {logOut} = useLoggedIn()
  const performLogout = useLogout(() => {
    logOut()
    router.pushDefaultRoot()
  })

  return (
    <div key={'header'} style={solidBacking ? s.headerSolid : s.header}>
      <div style={s.headerLeftContainer} onClick={() => router.pushMarketingSite()}>
        <img style={s.headerImage} src={addingBasePath(logoPath)} alt={t('productTitle')} />
        {useBetaTag && <BetaTag />}
      </div>
      {showLogout && (
        <ActionButton appearance="filled" size="small" text={t('Log out')} onClick={() => performLogout()} />
      )}
    </div>
  )
}
