import {TranslationKey} from 'notability-services-common-universal'
import {
  defineStylesWithBreaks,
  galleryHeaderHeight,
  useGLTranslation,
  useThemedStylesWithBreaks
} from 'notability-services-common-frontend'
import React, {ReactNode} from 'react'

import ErrorBoundary from './ErrorBoundary'
import {PageMetadata} from './PageMetadata'
import {LoginHeader} from './LoginHeader'
import {BackgroundClouds} from './BackgroundClouds'

export {galleryHeaderHeight}

interface Props {
  children?: ReactNode
  showLogout?: boolean
  pageTitle?: TranslationKey
  useGraphicBackground?: boolean
  useBetaTag?: boolean
}

const styles = defineStylesWithBreaks((theme) => {
  return {
    fullPageStyle: {
      color: theme.core.f1,
      height: '100vh',
      overflowY: 'hidden',
      zIndex: -10
    },

    mainPageStyle: {
      overflowY: 'hidden',
      height: '100vh',

      mobile: {
        overflowY: 'scroll'
      }
    },

    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '15px',
      fontStyle: 'normal',
      marginTop: '0px',

      mobile: {
        position: 'relative',
        height: '80vh'
      }
    }
  }
})

export function LoginLayout({showLogout, children, pageTitle, useGraphicBackground = true, useBetaTag = false}: Props) {
  const s = useThemedStylesWithBreaks(styles)
  const {t} = useGLTranslation()

  const translatedTitle = pageTitle ? t('appPageTitleSub', {pageTitle: t(pageTitle)}) : t('productTitle')

  return (
    <div style={s.fullPageStyle}>
      <PageMetadata title={translatedTitle} />
      {useGraphicBackground && <BackgroundClouds />}
      <div style={s.mainPageStyle}>
        <LoginHeader useBetaTag={useBetaTag} showLogout={showLogout} />
        <ErrorBoundary>
          <div style={s.container}>{children}</div>
        </ErrorBoundary>
      </div>
    </div>
  )
}
