import {defineStylesWithBreaks, useThemedStylesWithBreaks} from 'notability-services-common-frontend'
import {addingBasePath} from '../config'

const styles = defineStylesWithBreaks((theme) => {
  return {
    backgroundContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,

      mobile: {
        display: 'none'
      }
    },

    clouds: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '80vw',
      maxWidth: '1200px'
    }
  }
})

export function BackgroundClouds() {
  const s = useThemedStylesWithBreaks(styles)
  return (
    <div style={s.backgroundContainer}>
      <img src={addingBasePath('/backgroundClouds.svg')} style={s.clouds} alt={''} />
    </div>
  )
}
